import { socket } from "./editor-socket";
import { Utils } from "./utils";

socket.on("message", function (msg) {
  contentEl.innerHTML = msg;
});

const contentEl = document.querySelector(".content"),
  btnCopy = document.querySelector(".btn-copy");
contentEl.focus();

contentEl.addEventListener("keyup", (e) => {
  !e.ctrlKey && socket.emit("message", contentEl.innerHTML);
});

btnCopy.addEventListener("click", ({ target }) => {
  Utils.copyContent(contentEl);
  target.innerHTML = "code copied";
  setTimeout(() => (target.innerHTML = "copy code"), 1000);
});

document.addEventListener("visibilitychange", () => {
  if (!document.hidden) contentEl.focus();
});
