export class Utils {
    static copyContent(el) {
        if(!el) return;
        const temp = document.createElement('textarea');
        document.body.appendChild(temp);
        temp.innerHTML = el.innerText;
        temp.select();
        document.execCommand("copy");
        temp.remove();
    }
}
